// fix menu collapse on mobile
@include media-breakpoint-down(md) {
  .navbar .navbar-collapse .nav-toggler {
    margin-left: 3rem;
  }
}

@include media-breakpoint-up(md) {
  .mini-sidebar {
    .sidebartoggler {
      margin-left: 5rem;
    }

    .logo {
      padding: 0.3rem;
    }
  }
}

// pricing tool
@include media-breakpoint-down(sm) {
  .pricing-result .warehouse .container-item {
    margin-bottom: 0.5rem;
  }
}

// notes component
@include media-breakpoint-up(md) {
  .notes .form-inline textarea[name="note"] {
    width: 50%;
  }
}

// create order
@include media-breakpoint-up(md) {
  .card-group.create-order .pricing {
    max-width: 25%;
  }
}
