// Fonts
@import url('https://fonts.googleapis.com/css?family=Roboto');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';

// Datatables BS4
@import "~datatables.net-bs4/css/dataTables.bootstrap4.css";
@import "~datatables.net-fixedheader-bs4/css/fixedHeader.bootstrap4.css";


// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome';
@import '~@fortawesome/fontawesome-free/scss/regular';
@import '~@fortawesome/fontawesome-free/scss/solid';
@import '~@fortawesome/fontawesome-free/scss/brands';

// Elite Admin Template
// @import 'ea-main'; currently to slow to include like this

@import 'responsive';

.pricing-result {
  padding: 1.25rem;

  .warehouse {
    margin-bottom: .7rem;
    padding-bottom: .7rem;
    border-bottom: 1px solid gray;

    h4 {
      text-align: left;
      font-weight: 400;

    }

    .in-stock {
      color: green;
    }
  }
}


.orders-list {
  .table-sm td, .table-sm th {
    padding: 0.6rem;
  }
}

// Order Status colors
.status-id {
  &-2 { // Quote
    background-color: inherit;
  }
  &-3 { // Out for delivery
    background-color: #89ff6c;
  }
  &-7 { // Complete
    background-color: #676666;
    color: white;
  }
  &-11 { // Ready for Delivery
    background-color: #80ff00;
  }
}

.paid-in-full {
  background-color: #a6e2aa;
}

.delivery-schedule .highlight {
  background-color: $lightYellow !important;
}

.driver-payments {
  .table td, .table th {
    vertical-align: middle;
  }
}

// fix DataTable header to match existing top bar
table.dataTable.fixedHeader-floating {
  padding-top: 68px;
}

img.img-rounded {
  border-radius: 0.3rem;
  box-shadow: 0px 0px 4px 1px #020202;
  margin-bottom: 0.5rem;
}

// note component
.notes .card-title {
  cursor: pointer;
}

.order-detail-item {
  td {
    vertical-align: middle;
  }
}

.trust-badges {
  .stripe {
    width: 8rem;
  }
}
